import React from 'react';
import cx from 'classnames';
import step1Image from '../../images/process-step-1.png';
import step2Image from '../../images/process-step-2.png';
import step3Image from '../../images/process-step-3.png';
import step4Image from '../../images/process-step-4.png';
import styles from './Process.module.scss';

const Process = () => {
  return (
    <div className={styles.section}>
      <div className={styles.inner}>
        <div className={styles.step}>
          <div className={styles.containerButtonsAndImage}>
            <img src={step1Image} alt="phone with passcode" height={173} />
          </div>
          <div className={styles.description}>
            <h2 className={styles.headline}>ペイディアプリにログイン</h2>
          </div>
        </div>
        <div className={styles.step}>
          <img src={step2Image} alt="Paidyプラス" height={173} />
          <div className={styles.description}>
            <h2 className={styles.headline}>「アカウント」をタップ</h2>
          </div>
        </div>
        <div className={styles.step}>
          <div className={styles.imgContainer}>
            <img src={step3Image} alt="id card" height={173} />
          </div>
          <div className={styles.description}>
            <h2 className={styles.headline}>「本人確認する」をタップ</h2>
          </div>
        </div>
        <div className={styles.step}>
          <div className={styles.imgContainer}>
            <img src={step4Image} alt="budget slider" height={173} />
          </div>
          <div className={cx(styles.description, styles.descriptionStep4)}>
            <p className={styles.info}>
              身分証明証（運転免許証またはマイナンバーカード）と顔写真を撮影し、必要事項を入力して、本人確認を完成させる。
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Process;
