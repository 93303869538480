const isProduction = process.env.GATSBY_ENVIRONMENT === 'production';

const SMART_BANNER_API_KEYS = {
  PRODUCTION: '873047d9-4e41-4a07-a59c-107088e9d87a',
  STAGING: '5aeb59e5-87ad-48a5-b33b-0b42a784f626', // New
  // STAGING: 'b61d21c0-0fd6-4e54-96c6-ddeb262abce3', // Old
};

const SMART_BANNER_API_KEY = isProduction
  ? SMART_BANNER_API_KEYS.PRODUCTION
  : SMART_BANNER_API_KEYS.STAGING;

const SMART_BANNER_SCRIPT = `
  !function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){
  (t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},
  t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,
  o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),
  p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF","banners",{banners: {key: "${SMART_BANNER_API_KEY}"}});
  // Smart Banners are by default set to the max z-index value, so they won't be hidden by the website elements. This can be changed if you want some website components to be on top of the banner.
  AF('banners', 'showBanner', { bannerZIndex: 1000, additionalParams: { p1: "v1", p2: "v2"}});
`;

export default SMART_BANNER_SCRIPT;
