import { Link } from 'gatsby';
import React from 'react';

import styles from './UsageNote.module.scss';

const UsageNote = ({ text1 }) => {
  return (
    <div className={styles.container}>
      <div>
        {text1}
        <br />
        <br />
        詳しくは、 <Link to="/payments/">こちらから</Link>
      </div>
    </div>
  );
};

UsageNote.defaultProps = {
  text1:
    '日本郵便が販売する郵便切手類等でペイディをご利用いただくには、ペイディプラスへの無料アップグレードが必要です。厳格な本人確認完了後は、ご利用上限金額の確認*ができるので、安心してお買い物が楽しめます。',
};

export default UsageNote;
