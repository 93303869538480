// @flow
const currentYear = new Date().getFullYear();

const jdonLd = {
  '@context': 'http://schema.org',
  '@type': 'WebSite',
  url: 'https://paidy.com',
  image: '/logo.png',
  headline: 'Paidy',
  author: '@paidy',
  copyrightHolder: 'Paidy Inc.',
  copyrightYear: currentYear,
  audience: 'consumer',
  encodings: 'UTF-8',
  keywords:
    '買い物,決済,コンビニ,クレカ,後払い,あと払い,支払い,EC,代引き,通販,システム,導入,翌月,後払い ペイ',
  description:
    'Paidy(ペイディー)はオンラインショップで最もカンタンにご利用いただける決済サービスです。事前登録やクレジットカードは必要なく、携帯電話番号とメールアドレスのみで決済いただけます。お近くのコンビニまたは銀行で、まとめて月1回お支払いください。',
  inLanguage: 'ja',
  locationCreated: {
    address: {
      addressCountry: 'Japan',
      addressRegion: 'Tokyo',
      postalCode: '1076212',
      streetAddress: 'Midtown Tower 12F, 9-7-1, Akasaka, Minato-ku',
    },
  },
};

export default jdonLd;
