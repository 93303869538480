// @flow
import { Location } from '@reach/router';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import TopBanner from 'src/components/Landing/TopBanner';
import PaidyPlusEKYC from 'src/components/Landing/PaidyPlusEKYC';

import { fetchStaticLanding } from '../redux/landing';
import { titlePathnameMap } from '../constants';
import Can from '../components/Landing/Can';
import ConversionBottom from '../components/Landing/ConversionBottom';
import ConversionTop from '../components/Landing/ConversionTop';
import Footer from '../components/Landing/Footer';
import Plus from '../components/Landing/Plus';
import Point from '../components/Landing/Point';
import Process from '../components/Landing/Process';
import SEO from '../components/SEO';
import Step from '../components/Landing/Step';
import UsageNote from '../components/Landing/UsageNote';
import Useful from '../components/Landing/Useful';
import styles from '../styles/pages/landing.module.scss';

const EkycLandingPage = () => {
  const dispatch = useDispatch();

  const landingPage = useSelector((state) => state.landing);

  useEffect(() => {
    dispatch(fetchStaticLanding());
  }, [dispatch]);

  return (
    <div className={styles.landing}>
      <Location>
        {({ location }) => (
          <>
            {titlePathnameMap[location.pathname] && (
              <SEO
                title={titlePathnameMap[location.pathname]}
                pathname={location.pathname}
                audience="consumer"
              />
            )}
            <TopBanner />
            {landingPage && landingPage.data && (
              <>
                <ConversionTop />
                <UsageNote text1="本ストアでは、ペイディアプリから本人確認をしていただくと、ペイディをご利用いただけます。" />
                <PaidyPlusEKYC />

                <Process />
                <Point
                  data={{
                    ...landingPage.data.point,
                    topTitle: (
                      <>
                        欲しいものを、今すぐ。
                        <br />
                        支払いは、あとで大丈夫。
                      </>
                    ),
                  }}
                />
                <Step
                  data={{
                    ...landingPage.data.step,
                    topTitle: 'いま買いたい、かなえます。',
                    title1: '使い方は簡単、<br/>たったの３ステップ。',
                  }}
                />
                <Can
                  data={{
                    ...landingPage.data.can,
                    image1: {
                      file: { url: require('../images/app-paysle-2.png') },
                    },
                    image2: {
                      file: {
                        url: require('../images/app-usage-tooltip-plus.png'),
                      },
                    },
                    image3: {
                      file: {
                        url: require('../images/app-home-plus-bill-generated.png'),
                      },
                    },
                  }}
                />
                <Plus
                  data={{
                    ...landingPage.data.plus,
                    image1: {
                      file: { url: require('../images/app-onboarding-2.png') },
                    },
                    image2: {
                      file: { url: require('../images/app-onboarding.jpg') },
                    },
                  }}
                />
                <ConversionBottom
                  textPc={`アプリをダウンロードし <br />
                  ペイディカードを発行する。`}
                  textSp={`アプリをダウンロードし <br />
                  ペイディカードを発行する。`}
                />
                <Useful
                  data={{
                    ...landingPage.data.useful,
                    title: (
                      <>
                        ペイディのすべての機能を、
                        <br />
                        ペイディプラスで。
                      </>
                    ),
                  }}
                />
                <Footer
                  data={{
                    ...landingPage.data.footer,
                    title:
                      'ペイディについて、わからないこと、不安なことはいつでもお問い合わせください。',
                  }}
                />
              </>
            )}
          </>
        )}
      </Location>
    </div>
  );
};

export default EkycLandingPage;
