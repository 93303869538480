import React from 'react';
import img3Pay from 'src/images/landing/3pay.png';
import styles from './PaidyPlusEKYC.module.scss';

const PaidyPlusEKYC = () => (
  <section>
    <div className={styles.container}>
      <img src={img3Pay} alt="imgPaidyPlus2" className={styles.img2} />
      <div className={styles.right}>
        <h1 className={styles.title}>
          ３回あと払いが
          <br />
          分割手数料無料*
        </h1>
        <p className={styles.description}>
          ペイディアプリから本人確認をすると、３回あと払いが分割手数料無料*で利用可能。高額商品もかしこく購入できます。
        </p>
        <div className={styles.sectionNote}>
          <p className={styles.note}>*銀行振込・口座振替のみ分割手数料無料。</p>
          <p className={styles.note}>
            **３回あと払いは1回の決済につき3,000円以上の利用金額でお申込みが可能です。一部のオンラインショップでは３回あと払いをご利用いただけません。
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default PaidyPlusEKYC;
